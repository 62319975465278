import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert,
} from "reactstrap";

//FreeBus
import { API, apic, secu_gitaner } from "./../../Core/Lib/constants";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      user: "",
      password: "",
      message: false,
      message_type: "",
      message_text: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
    this.reset_msg();
  }

  reset_msg() {
    this.setState({
      message: false,
    });
  }

  handleSubmit(event) {
    var pater = this;
    var empresa = this.state.company;

    apic({
      method: "POST",
      url: API + "auth",
      params: {
        eid: btoa(this.state.company),
        user: secu_gitaner(this.state.user),
        key: secu_gitaner(this.state.password),
      },
    }).then(function (response) {
      if (response.data.exito === true) {
        localStorage.setItem("empresa", btoa(empresa));
        localStorage.setItem("session", btoa(response.data.datos.token));
        pater.setState({
          message: true,
          message_type: "success",
          message_text: "Login correcto",
        });
        setTimeout(() => {
          window.location.href = "/#/home";
          window.location.reload();
        }, 1000);
      } else {
        pater.setState({
          message: true,
          message_type: "danger",
          message_text: "Credenciales no válidas",
        });
      }
    });
    event.preventDefault();
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <div
                        className="brand-card-header"
                        style={{ margin: "30px 0px 50px 0px" }}
                      >
                        <img
                          src={require("./../../../assets/img/brand/logo.svg")}
                          alt="FreeBus"
                          className="logo_login"
                        />
                      </div>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-home"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="company"
                          value={this.state.value}
                          onChange={this.handleChange}
                          placeholder="Empresa"
                          required
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="user"
                          value={this.state.value}
                          onChange={this.handleChange}
                          placeholder="Usuario"
                          required
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          name="password"
                          value={this.state.value}
                          onChange={this.handleChange}
                          placeholder="Password"
                          required
                        />
                      </InputGroup>
                      <Row>
                        {this.state.message && (
                          <Col xs="12">
                            <Alert color={this.state.message_type}>
                              {this.state.message_text}
                            </Alert>
                          </Col>
                        )}
                        <Col xs="12">
                          <Button color="primary" className="px-4 float-right">
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
