import React, { Component } from "react";
import { Nav } from "reactstrap";
import PropTypes from "prop-types";

import { Avatar } from "./../../views/Core/Header";

import {
  AppAsideToggler,
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import logo from "../../assets/img/brand/logo.svg";
import logo_redu from "../../assets/img/brand/logo_redu.svg";
import { Mensajes, Chats } from "../../views/Core/Header/Buttons";
import { isMobile } from "../../views/Core/Lib/constants";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, height: 33, alt: "FreeBUS" }}
          minimized={{ src: logo_redu, width: 30, height: 30, alt: "FreeBUS" }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          {!isMobile() && (
            <>
              <li className="nav-item icon-header">
                <Mensajes path={this.props.path} type="header"></Mensajes>
              </li>
              <li className="nav-item icon-header">
                <Chats path={this.props.path} type="header"></Chats>
              </li>
            </>
          )}
          <AppHeaderDropdown direction="down">
            <Avatar></Avatar>
          </AppHeaderDropdown>
        </Nav>
        <AppAsideToggler className="d-md-down-none" />
      </React.Fragment>
    );
  }
}
DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
