export default {
  items: [
    {
      name: "Home",
      url: "/home",
      icon: "icon-home",
    },
    {
      title: true,
      name: "Gestión",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Vacíos",
      url: "/resources",
      icon: "fa fa-bus",
      class: "vacios-color",
      children: [
        {
          name: "Publicar vacío",
          url: "/resources/new",
          icon: "fa fa-plus",
          class: "children_vacios",
        },
        {
          name: "Buscador por zonas",
          url: "/resources/search",
          icon: "fa fa-search",
          class: "children_vacios",
        },
        {
          name: "Mis vacíos",
          url: "/posts/resources",
          icon: "fa fa-share",
          class: "children_vacios",
        },
        {
          name: "Me interesan",
          url: "/requests/resources",
          icon: "fa fa-reply",
          class: "children_vacios",
        },
      ],
    },
    {
      name: "Servicios",
      url: "/routes",
      icon: "fa fa-globe",
      class: "servicios-color",
      children: [
        {
          name: "Publicar servicio",
          url: "/routes/new",
          icon: "fa fa-plus",
          class: "children_servicios",
        },
        {
          name: "Buscador por zonas",
          url: "/routes/search",
          icon: "fa fa-search",
          class: "children_servicios",
        },
        {
          name: "Mis servicios",
          url: "/posts/routes",
          icon: "fa fa-share",
          class: "children_servicios",
        },
        {
          name: "Me interesan",
          url: "/requests/routes",
          icon: "fa fa-reply",
          class: "children_servicios",
        },
      ],
    },
  ],
};
