import React, { Component } from "react";
import { DropdownItem } from "reactstrap";

import { API, apic, AppContext } from "./../Lib/constants";

class Logout extends Component {
  static contextType = AppContext;

  killsess(context) {
    apic.get(API + "kill_sess", {
      params: {
        eid: context.empresa,
      },
      headers: {
        Authorization: context.session,
      },
    });
    localStorage.removeItem("empresa");
    localStorage.removeItem("session");
    window.location.href = "/#";
    window.location.reload();
  }

  render() {
    return (
      <DropdownItem
        onClick={() => {
          this.killsess(this.context);
        }}
      >
        <i className="fa fa-power-off"></i> Logout
      </DropdownItem>
    );
  }
}

export default Logout;
