import React, { Component } from "react";
import { Badge, DropdownItem, Button } from "reactstrap";
import { API, apic, AppContext } from "./../../Lib/constants";

class Chats extends Component {
  static contextType = AppContext;

  state = {
    nuevos: 0,
    tipo: this.props.type,
    empresa: this.context.empresa,
    sesion: this.context.session,
  };

  mensajes_info(token, empresa, status) {
    apic
      .get(API + "user/status/chats", {
        params: {
          eid: empresa,
        },
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        status.setState({
          nuevos: response.data.datos.num,
        });
      });
  }

  componentWillMount() {
    this.mensajes_info(this.state.sesion, this.state.empresa, this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path && prevProps.path !== null) {
      this.mensajes_info(this.state.sesion, this.state.empresa, this);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.tipo === "header" ? (
          <Button className="icon-header" href="#/user_chats">
            <i className="fa fa-comments"></i>
            {this.state.nuevos > 0 && (
              <Badge color="warning">{this.state.nuevos}</Badge>
            )}
          </Button>
        ) : (
          <DropdownItem href="#/user_chats">
            <i className="fa fa-comments"></i> Chats
            {this.state.nuevos > 0 && (
              <Badge color="warning">{this.state.nuevos}</Badge>
            )}
          </DropdownItem>
        )}
      </React.Fragment>
    );
  }
}

export default Chats;
