import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

class Loading extends Component {
  render() {
    return (
      <div className="animated fadeIn">
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="4">
                <div className="clearfix">
                  <img
                    src={require("./../../../src/assets/img/brand/logo.svg")}
                    alt="Loading"
                    className="img-loading"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Loading;
