import React, { Component } from "react";
import { API, apic, AppContext } from "./../../Lib/constants";
import CompanyLogo from "./../InfoUserlib/CompanyLogo";
import { Button } from "reactstrap";

class ChatsAside extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);
    this.state = {
      msg: [],
      empresa: context.empresa,
      sesion: context.session,
    };
    this.get_mensajes = this.get_mensajes.bind(this);
    this.dale_mandanga = this.dale_mandanga.bind(this);
    this.redirige = this.redirige.bind(this);
  }

  get_mensajes(status) {
    apic
      .get(API + "user/status/chats", {
        params: {
          eid: this.state.empresa,
          last_chats: true,
        },
        headers: {
          Authorization: this.state.sesion,
        },
      })
      .then(function (response) {
        if (response.data.exito) {
          status.setState({
            msg: response.data.datos,
          });
        }
      });
  }

  componentWillMount() {
    this.get_mensajes(this);
  }

  dale_mandanga() {
    this.get_mensajes(this);
  }

  redirige = (id) => () => {
    if (window.location.hash.includes("#/offers/show/") === true) {
      if (window.location.hash.includes(id) === false) {
        window.location.href = "#/offers/show/" + id;
        window.location.reload();
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.msg.map((msg, idx) => (
          <div key={idx} className="message">
            {idx === 0 && (
              <Button
                onClick={this.dale_mandanga}
                className="avatar-status badge badge-primary float-right"
              >
                <i className="fa fa-refresh"></i>
              </Button>
            )}
            <div className="py-3 pb-5 mr-3 float-left">
              <div className="avatar">
                <CompanyLogo path={msg.avatar}></CompanyLogo>
                {msg.leido === false && (
                  <span className="avatar-status badge-warning"></span>
                )}
              </div>
            </div>
            <div>
              <small className="text-muted">{msg.remitente}</small>
            </div>
            <div>
              <small className="text-muted mt-1">{msg.ult_msg}</small>
            </div>
            <div className="text-truncate font-weight-bold">
              <a
                href={"#/offers/show/" + msg.id}
                onClick={this.redirige(msg.id)}
              >
                {msg.origen + "-" + msg.destino + " " + msg.salida}
              </a>
            </div>
            <small className="text-muted">{msg.mensaje}</small>
            <div>
              {msg.propia === true && (
                <span className="avatar-status badge badge-home">
                  <i className="fa fa-home"></i>
                </span>
              )}
              {msg.tipo === "Servicio" && (
                <span className="avatar-status badge badge-servicios">
                  <i className="fa fa-globe"></i>
                </span>
              )}
              {msg.tipo === "Vacio" && (
                <span className="avatar-status badge badge-vacios">
                  <i className="fa fa-bus"></i>
                </span>
              )}
            </div>
            <hr></hr>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default ChatsAside;
