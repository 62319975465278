import React, { useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import { Login, Loading } from "./views/Pages";

// import { renderRoutes } from 'react-router-config';

//FreeBus
import { API, apic, AppContext } from "./views/Core/Lib/constants";
import GlobalError from "./views/Core/Body/Utils/GlobalError";

const App = () => {
  const [state, setState] = useState({
    active: null,
    comError: null,
  });
  const [session, setSession] = useState({
    load: false,
    session: null,
    empresa: null,
  });

  const checksession = (callback) => {
    if (session.load === true) {
      apic
        .get(API + "check", {
          params: {
            eid: session.empresa,
          },
          headers: {
            Authorization: session.session,
          },
        })
        .then(function (response) {
          callback({ active: response.data.exito });
        })
        .catch(() => {
          callback({ comError: true });
        });
    }
  };

  useEffect(() => {
    setSession({
      session: localStorage.getItem("session"),
      empresa: localStorage.getItem("empresa"),
      load: true,
    });
  }, []);

  useEffect(() => {
    checksession(({ active, comError }) => {
      setState({ ...state, active, comError });
    });
  }, [session]);

  const path = () => {
    if (state.comError === true) {
      return (
        <GlobalError message="No hay conectividad con el servidor, por favor inténtelo de nuevo más tarde" />
      );
    }
    if (state.active === true) {
      return <Route path="/" name="Home" component={DefaultLayout} />;
    }
    if (state.active === false) {
      return <Route path="/" name="Login" component={Login} />;
    }
    if (state.active === null) {
      return <Route path="/" name="Loading" component={Loading} />;
    }
  };

  return (
    <AppContext.Provider value={session}>
      <HashRouter>
        <Switch>{path()}</Switch>
      </HashRouter>
    </AppContext.Provider>
  );
};

export default App;
