import React, { Component } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import UserGlobal, { UserContext } from "./../Body/InfoUserlib/UserGlobal";

import { AppContext } from "./../Lib/constants";
import { Logout } from "./../Header";

import { Mensajes, Chats } from "./Buttons";
import { isMobile } from "./../Lib/constants";

class Avatar extends Component {
  static contextType = AppContext;

  render() {
    return (
      <UserGlobal>
        <React.Fragment>
          <DropdownToggle nav>
            <UserContext.Consumer>
              {(context) => context.avatar}
            </UserContext.Consumer>
          </DropdownToggle>
          <DropdownMenu right style={{ right: "auto" }}>
            <DropdownItem header tag="div" className="text-center">
              <strong>
                <UserContext.Consumer>
                  {(context) => context.name}
                </UserContext.Consumer>
              </strong>
            </DropdownItem>
            {isMobile() && (
              <>
                <Mensajes></Mensajes>
                <Chats></Chats>
                <DropdownItem header tag="div" className="text-center">
                  <strong>Configuración</strong>
                </DropdownItem>
              </>
            )}

            <DropdownItem href="#/user_info">
              <i className="fa fa-user"></i> Usuario
            </DropdownItem>
            <UserContext.Consumer>
              {(context) => {
                if (context.admin) {
                  return (
                    <DropdownItem href="#/admin">
                      <i className="fa fa-wrench"></i> Admin
                    </DropdownItem>
                  );
                }
              }}
            </UserContext.Consumer>
            <Logout></Logout>
          </DropdownMenu>
        </React.Fragment>
      </UserGlobal>
    );
  }
}

export default Avatar;
