import React from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardGroup,
  CardBody,
} from "reactstrap";

import Logo from "./../../../../assets/img/brand/logo.svg";

const GlobalError = ({ message, logo = true }) => {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="7">
            <CardGroup>
              <Card className="p-9">
                <CardBody>
                  {logo === true && (
                    <div
                      style={{ margin: "8% 0" }}
                      className="brand-card-header"
                    >
                      <img src={Logo} alt="FreeBus" className="logo_login" />
                    </div>
                  )}
                  <Col xs="12">
                    <Alert color="danger">{message}</Alert>
                  </Col>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GlobalError;
