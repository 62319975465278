import React from "react";
import Loadable from "react-loadable";
import { Redirect } from "react-router-dom";

function Loading() {
  return <div>Cargando...</div>;
}

const Home = Loadable({
  loader: () => import("./views/Core/Body/Home"),
  loading: Loading,
});

const RouteShow = Loadable({
  loader: () => import("./views/Core/Body/Routes/RouteShow"),
  loading: Loading,
});

const RouteNew = Loadable({
  loader: () => import("./views/Core/Body/Routes/RouteNew"),
  loading: Loading,
});

const RouteSearch = Loadable({
  loader: () => import("./views/Core/Body/Routes/RouteSearch"),
  loading: Loading,
});

const UserChats = Loadable({
  loader: () => import("./views/Core/Body/UserChats"),
  loading: Loading,
});

const Posts = Loadable({
  loader: () => import("./views/Core/Body/Posts/PostShow"),
  loading: Loading,
});

const Requests = Loadable({
  loader: () => import("./views/Core/Body/Requests/RequestShow"),
  loading: Loading,
});

const OfferShow = Loadable({
  loader: () => import("./views/Core/Body/Offers/OfferShow"),
  loading: Loading,
});

const ResourceShow = Loadable({
  loader: () => import("./views/Core/Body/Resources/ResourceShow"),
  loading: Loading,
});

const ResourceSearch = Loadable({
  loader: () => import("./views/Core/Body/Resources/ResourceSearch"),
  loading: Loading,
});

const ResourceNew = Loadable({
  loader: () => import("./views/Core/Body/Resources/ResourceNew"),
  loading: Loading,
});

const InfoUser = Loadable({
  loader: () => import("./views/Core/Body/InfoUser"),
  loading: Loading,
});

const UserMessages = Loadable({
  loader: () => import("./views/Core/Body/UserMessages"),
  loading: Loading,
});

const Show = Loadable({
  loader: () => import("./views/Core/Body/UserMessageslib/Show"),
  loading: Loading,
});

const Admin = Loadable({
  loader: () => import("./views/Core/Body/Admin"),
  loading: Loading,
});

const Default = () => {
  return <Redirect to="/home" />;
};

const routes = [
  { path: "/", exact: true, name: "Home", component: Default },
  {
    path: "/admin",
    exact: true,
    name: "Panel Administracion",
    component: Admin,
  },
  {
    path: "/user_info",
    exact: true,
    name: "Información Usuario",
    component: InfoUser,
  },
  {
    path: "/user_messages",
    exact: true,
    name: "Notificaciones Usuario",
    component: UserMessages,
  },
  {
    path: "/user_messages/show/:id",
    exact: true,
    name: "Mensaje",
    component: Show,
  },
  {
    path: "/resources/new",
    exact: true,
    name: "Publicar Vacío",
    component: ResourceNew,
  },
  {
    path: "/resources/show/:id",
    exact: true,
    name: "Vacío",
    component: ResourceShow,
  },
  {
    path: "/resources/search",
    exact: true,
    name: "Buscar Vacío",
    component: ResourceSearch,
  },
  {
    path: "/offers/show/:id",
    exact: true,
    name: "Solicitud",
    component: OfferShow,
  },
  {
    path: "/requests/:type",
    exact: true,
    name: "Solicitudes",
    component: Requests,
  },
  {
    path: "/posts/:type",
    exact: true,
    name: "Publicaciones",
    component: Posts,
  },
  { path: "/user_chats", exact: true, name: "Chats", component: UserChats },
  {
    path: "/routes/search",
    exact: true,
    name: "Buscar Servicio",
    component: RouteSearch,
  },
  {
    path: "/routes/new",
    exact: true,
    name: "Publicar Servicio",
    component: RouteNew,
  },
  {
    path: "/routes/show/:id",
    exact: true,
    name: "Servicio",
    component: RouteShow,
  },
  { path: "/home", exact: true, name: "Dashboard", component: Home },
];

export default routes;
