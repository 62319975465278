import React from "react";

const LinterVaciosServicios = ({ path }) => {
  const clName = path.includes("resources")
    ? "linter linter-vacios"
    : path.includes("routes")
    ? "linter linter-servicios"
    : "linter";
  return <div className={clName}></div>;
};

export default LinterVaciosServicios;
