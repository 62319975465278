import React, { Component } from 'react';
import {BASE} from './../../Lib/constants';

export const UserContext = React.createContext()

class CompanyLogo extends Component {
  
  render() {
    return (
      <React.Fragment>
        { 
          this.props.path === null ?
          (
            <img src= "assets/img/avatars/1.jpg" alt="default avatar" className="avatar_header" />
          )
          :
          (
            <img src= {BASE + this.props.path} alt="default avatar" className="avatar_header" />
          )
        }
      </React.Fragment>
    )
  }
}

export default CompanyLogo