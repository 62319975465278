import React, { Component } from "react";
import { BASE, API, apic, AppContext } from "./../../Lib/constants";

export const UserContext = React.createContext();

class UserGlobal extends Component {
  static contextType = AppContext;

  state = {
    name: "",
    admin: false,
    email: "",
    avatar: null,
    empresa: "",
    inbox: null,
    nemail: null,
  };

  user_info(token, empresa, status) {
    apic
      .get(API + "user/info", {
        params: {
          eid: empresa,
        },
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        var avatar;
        if (response.data.datos.avatar === null) {
          avatar = (
            <img
              src={"assets/img/avatars/1.jpg"}
              className="img-avatar"
              alt="Default Avatar"
            />
          );
        } else {
          avatar = (
            <img
              src={BASE + response.data.datos.avatar}
              className="img-avatar"
              alt={atob(empresa)}
            />
          );
        }

        status.setState({
          name: response.data.datos.name,
          admin: response.data.datos.admin,
          empresa: response.data.datos.empresa,
          nemail: response.data.datos.nemail,
          email: response.data.datos.email,
          inbox: response.data.datos.inbox,
          avatar: avatar,
        });
      });
  }

  componentWillMount() {
    var sesion = this.context.session;
    var empresa = this.context.empresa;
    this.user_info(sesion, empresa, this);
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserGlobal;
