import React, { Component } from "react";
import { Badge, Button, DropdownItem } from "reactstrap";
import { API, apic, AppContext } from "./../../Lib/constants";

class Mensajes extends Component {
  static contextType = AppContext;

  state = {
    nuevos: 0,
    tipo: this.props.type,
    sesion: this.context.session,
    empresa: this.context.empresa,
  };

  mensajes_info(token, empresa, status) {
    apic
      .get(API + "user/status/messages", {
        params: {
          eid: empresa,
        },
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        status.setState({
          nuevos: response.data.datos,
        });
      });
  }

  componentWillMount() {
    this.mensajes_info(this.state.sesion, this.state.empresa, this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path && prevProps.path !== null) {
      this.mensajes_info(this.state.sesion, this.state.empresa, this);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.tipo === "header" ? (
          <Button className="icon-header" href="#/user_messages">
            <i className="fa fa-envelope-o"></i>
            {this.state.nuevos > 0 && (
              <Badge color="danger">{this.state.nuevos}</Badge>
            )}
          </Button>
        ) : (
          <DropdownItem
            className={this.state.tipo === "header" ? "icon-header" : null}
            href="#/user_messages"
          >
            <i className="fa fa-envelope-o"></i>
            {this.state.tipo !== "header" ? "Notificaciones" : null}
            {this.state.nuevos > 0 && (
              <Badge color="danger">{this.state.nuevos}</Badge>
            )}
          </DropdownItem>
        )}
      </React.Fragment>
    );
  }
}

export default Mensajes;
